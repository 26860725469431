/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, {ChangeEvent, useEffect, useState} from 'react';

// Chakra imports
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Icon,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Table, TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure
} from '@chakra-ui/react';
import axios, {AxiosError} from "axios";
import {useForm} from 'react-hook-form';
import Card from 'components/card/Card';
import {CheckCircleIcon, ChevronLeftIcon, ChevronRightIcon, WarningTwoIcon} from "@chakra-ui/icons";
import {saveSiteUrl} from "../../../services/useAPICaller";

interface Order {
  // merchantId: string;
  orderId: string;
  concept?: string
  // recipient: string
  sessionType: string;
  // amount: number;
  // currency: string,
  template: string
  receipts: string[],
  createdDate: string,
  status: string
}


export default function Fullfill() {
  // Chakra Color Mode
  const {isOpen: isAddEndpointOpen, onOpen: onAddEndpointOpen, onClose: onAddEndpointClose} = useDisclosure()
  const {isOpen: isSavedSuccessOpen, onOpen: onSavedSuccessOpen, onClose: onSavedSuccessClose} = useDisclosure()
  const {isOpen: isWooAuthOpen, onOpen: onWooAuthOpen, onClose: onWooAuthClose} = useDisclosure()
  const [webhooks, setWebhooks] = useState<Array<string>>();
  const [authorizedSiteUrl, setAuthorizedSiteUrl] = useState<string>();
  const [authorizedSiteStatus, setAuthorizedSiteStatus] = useState<Boolean>();
  const [isAuthorizeSiteStatusLoading, setIsAuthorizeSiteStatusLoading] = useState<boolean>(true);
  const [isAuthRedirecting, setIsAuthRedirecting] = useState<boolean>();
  const [areBusinessUrlAndSiteUrlEquals, setAreBusinessUrlAndSiteUrlEquals] = useState<boolean>(true);
  const [businessUrl, setBusinessUrl] = useState<string>();
  const [webhooksApiKey, setWebhooksApiKey] = useState<string>();
  const [userId, setUserId] = useState<string>();

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [currentData, setCurrentData] = useState(Array<Order>);
  const [isOrdersLoading, setOrdersLoading] = useState<boolean>(false);

  const nextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };


  const {
    handleSubmit,
    register,
    control,
    formState: {errors},
    reset
  } = useForm()

  useEffect(() => {
    getPaymentSettings();
    getWebhookApiKeyFromPaymentSettings();
    getAuthorizedSiteStatus();
  }, [reset]);

  useEffect(() => {
    getPaidOrders(currentPage, pageSize);
  }, [currentPage, pageSize]);


  function onSubmit(values: any) {
    return new Promise((resolve) => {
      saveWebhookEndpoint(values.endpoint);
    })
  }

  function onWooSubmit(values: any) {
    return new Promise((resolve) => {
      saveWoocommerceConsumerCredentials(values.wooConsumerKey, values.wooConsumerSecret);
    })
  }

  async function getPaymentSettings() {

    try {
      let response = await axios.get(process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL + '/payment_settings', {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('jwtToken')
        },
        withCredentials: true
      });
      setWebhooks(response.data.webhookEndpoints);
      setBusinessUrl(response.data.businessUrl);
      setUserId(response.data.mail);
      // setWooConsumerKey(webhooksResponse.data.woocommerceConsumerKey);
      // setWooConsumerSecret(webhooksResponse.data.woocommerceConsumerSecret);
    } catch (error) {
      const err = error as AxiosError
      if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
        window.location.replace("/#/auth/set-up");
      }
    }
  }

  async function getWebhookApiKeyFromPaymentSettings() {

    try {
      let webhooksResponse = await axios.post(process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL + '/payment_settings/webhooks/key', {}, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('jwtToken')
        },
        withCredentials: true
      });
      setWebhooksApiKey(webhooksResponse.data);
    } catch (error) {
      const err = error as AxiosError
      if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
        window.location.replace("/#/auth/set-up");
      }
    }
  }

  async function getAuthorizedSiteStatus() {

    try {
      let response = await axios.get(process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL + '/payment_settings/woocommerce/site/status', {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('jwtToken')
        },
        withCredentials: true
      });
      setAuthorizedSiteUrl(response.data.siteUrl);
      setAuthorizedSiteStatus(200 === response.status);
      setIsAuthorizeSiteStatusLoading(false);
      // setWooConsumerKey(webhooksResponse.data.woocommerceConsumerKey);
      // setWooConsumerSecret(webhooksResponse.data.woocommerceConsumerSecret);
    } catch (error) {
      const err = error as AxiosError
      if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
        window.location.replace("/#/auth/set-up");
      }
      setIsAuthorizeSiteStatusLoading(false);
    }
  }

  async function saveWoocommerceConsumerCredentials(ck: string, cs: string) {

    try {
      let response = await axios.post(process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL + '/payment_settings/woocommerce/credentials',
        {"consumerKey": ck, "consumerSecret": cs}, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem('jwtToken')
          },
          withCredentials: true
        });
      if (response.status === 200) {
        onSavedSuccessOpen();
        setTimeout(() => {
          onSavedSuccessClose();
        }, 3 * 1000);
      }
    } catch (error) {
      const err = error as AxiosError
      if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
        window.location.replace("/#/auth/set-up");
      }
    }
  }

  async function saveWebhookEndpoint(endpoint: string) {
    try {
      var webhooksResponse = await axios.post(process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL + '/payment_settings/endpoints', {"endpoint": endpoint}, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('jwtToken')
        },
        withCredentials: true
      });
      setWebhooks(webhooksResponse.data);
      onAddEndpointClose();
    } catch (error) {
      const err = error as AxiosError
      if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
        window.location.replace("/#/auth/set-up");
      }
    }
  }

  async function deleteWebhook(endpoint: string) {
    try {
      var webhooksResponse = await axios.delete(process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL + `/payment_settings/endpoints/${endpoint}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('jwtToken')
        },
        withCredentials: true
      });
      setWebhooks(webhooksResponse.data);
      onAddEndpointClose();
    } catch (error) {
      const err = error as AxiosError
      if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
        window.location.replace("/#/auth/set-up");
      }
    }
  }

  function handleOnOpen() {
    onAddEndpointOpen();
  }

  function handleWoocommerceAuthorization() {

    setIsAuthRedirecting(true);
    authorizedSiteUrl && saveSiteUrl(authorizedSiteUrl);
    window.location.replace(`${authorizedSiteUrl ? authorizedSiteUrl : businessUrl}/wc-auth/v1/authorize?callback_url=${process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL}/payment_settings/woocommerce/authorization&app_name=Sprintcheckout&user_id=${userId}&scope=read_write&return_url=https://dashboard.sprintcheckout.com/#/admin/notifications`);
  }

  async function deleteSiteAuthorization() {

    setIsAuthorizeSiteStatusLoading(true);
    try {
      let response = await axios.delete(process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL + '/payment_settings/woocommerce/authorization', {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('jwtToken')
        },
        withCredentials: true
      });
      setAuthorizedSiteUrl(response.data.siteUrl);
      setAuthorizedSiteStatus(200 === response.status);
      setIsAuthorizeSiteStatusLoading(false);
    } catch (error) {
      const err = error as AxiosError
      if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
        window.location.replace("/#/auth/set-up");
      }
      setIsAuthorizeSiteStatusLoading(false);
    }
  }

  async function getPaidOrders(page = 0, pageSize = 10) {

    try {
      setOrdersLoading(true);
      let response = await axios.get(`${process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL}/orders?page=${page}&size=${pageSize}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('jwtToken')
        },
        withCredentials: true
      });
      setCurrentData(response.data.content);
      const totalItems = response.data.totalSize;
      setTotalPages(Math.ceil(totalItems / pageSize));
      setOrdersLoading(false);
    } catch (error) {
      const err = error as AxiosError
      if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
        console.log("Unauthorized")
        window.location.replace("/#/auth/set-up");
      }
    }
  }

  function isAuthorizeSite() {

    return authorizedSiteUrl !== undefined && authorizedSiteStatus !== undefined && authorizedSiteUrl !== "" && authorizedSiteStatus !== false;
  }


  const handleSiteUrlChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    setAuthorizedSiteUrl(value);
    if (value === businessUrl) {
      setAreBusinessUrlAndSiteUrlEquals(true);
    } else {
      setAreBusinessUrlAndSiteUrlEquals(false);
    }
  };

  return (
    <>
      <form key={"webhooks-form"} id={"webhooks-form"} onSubmit={handleSubmit(onSubmit)}>
        {/***********************************   WEBHOOKS MODAL   **********************************/}
        <Modal
          isOpen={isAddEndpointOpen}
          onClose={onAddEndpointClose}
        >
          <ModalOverlay/>
          <ModalContent>
            <ModalHeader>New webhook</ModalHeader>
            <ModalCloseButton/>
            <ModalBody pb={6}>
              <Input {...register("endpoint")}
                     placeholder='https://mystore.com/mywebhook'
                     key="webhook-endpoint"/>
            </ModalBody>

            <ModalFooter>
              <Button key={"wh-save-button"} variant="brand" type={"submit"} form={"webhooks-form"}>Save</Button>
              <Button key={"wh-cancel-button"} onClick={onAddEndpointClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Box pt={{base: '180px', md: '80px', xl: '80px'}}>
          <Card mb='20px'>
            {/***********************************   ADD ENDPOINT BUTTON   **********************************/}
            <SimpleGrid mb='20px' columns={{sm: 1, md: 2}} spacing={{base: '20px', xl: '20px'}}>
              <Flex align='center' w='100%' justify='space-between' mb='30px'>
                <Text fontWeight='bold' fontSize='2xl' mb='4px'>
                  Webhooks
                </Text>
              </Flex>
              <Flex align='end' w='100%' justify='flex-end' mb='30px'>
                <ButtonGroup alignItems={"right"} variant="outline" spacing="6">
                  <Button variant="brand" onClick={handleOnOpen}>Add an endpoint</Button>
                </ButtonGroup>
              </Flex>
            </SimpleGrid>
            {/***********************************   WEBHOOKS API KEY   **********************************/}
            {webhooksApiKey && <Card>
                <Flex align='center' w='100%' justify='center' mb='30px'>
                    <Text fontSize='l' mb='4px' fontStyle={"italic"}>
                        Your webhooks API key is {webhooksApiKey}
                    </Text>
                </Flex>
            </Card>}
            {/***********************************   WEBHOOK ENDPOINTS   **********************************/}
            {(!webhooks && !webhooksApiKey) &&
                <Flex align='center' w='100%' justify='center'>
                    <Spinner thickness='6px' speed='0.65s' emptyColor='gray.200' color='blue.500' boxSize={24} mb={20}/>
                </Flex>
            }
            {(!webhooks && webhooksApiKey) &&
                <Flex align='center' w='100%' justify='center' mb='30px'>
                    <Text fontSize='l' mb='4px' color={"grey"}>
                        No webhooks created yet. Click on 'Add an endpoint' to add one
                    </Text>
                </Flex>
            }
            {webhooks &&
                <TableContainer borderColor={"black"}>
                    <Table variant='simple'>
                        <Tbody>
                          {webhooks && webhooks.map((webhook, index) => {
                            return <Tr key={`webhook-${index}`}>
                              <Td textAlign={"left"}>{webhook}</Td>
                              <Td textAlign={"right"}><Button
                                onClick={() => deleteWebhook(webhook)}>Delete</Button></Td>
                            </Tr>
                          })}
                        </Tbody>
                    </Table>
                </TableContainer>
            }
          </Card>
          <Flex align='center' w='100%' justify='center' mb='30px'>
            <Text fontSize='l' mb='4px' color={"grey"}>
              Learn how to use your Webhooks to manage your orders by checking out our <Link
              textDecoration={"underline"}
              href="https://docs.sprintcheckout.com"
              isExternal>Docs</Link>
            </Text>
          </Flex>
        </Box>
      </form>

      {/***********************************   PLUGINS CONFIG (WOOCOMMERCE, ETC.)  **********************************/}
      <Box pt={{base: '180px', md: '80px', xl: '10px'}}>
        <form key={"plugins-form"} id={"woocommerceForm"} onSubmit={handleSubmit(onWooSubmit)}>
          <Modal key={"data-saved"} isOpen={isSavedSuccessOpen} onClose={onSavedSuccessClose}>
            <ModalOverlay/>
            <ModalContent>
              <ModalHeader>Settings saved</ModalHeader>
              <ModalCloseButton/>
              <ModalBody>
                <Alert status='success' variant='subtle'>
                  <AlertIcon/>
                  Your data have been saved successfully!
                </Alert>
              </ModalBody>
              <ModalFooter>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/***********************************   WOOCOMMERCE AUTHORIZATION MODAL   **********************************/}
          <Modal
            isOpen={isWooAuthOpen} onClose={onWooAuthClose}
          >
            <ModalOverlay/>
            <ModalContent>
              <ModalHeader>Site URL</ModalHeader>
              <ModalCloseButton/>
              <ModalBody pb={6}>
                <Input {...register("siteUrl")}
                       defaultValue={businessUrl}
                       onChange={handleSiteUrlChange}
                       placeholder='https://mystore.com'/>
                {!areBusinessUrlAndSiteUrlEquals &&
                    <HStack spacing={2} align="center" mt={2}>
                        <Icon as={WarningTwoIcon} color="orange.400"/>
                        <Text mt={1} color="orange.400">
                            Your business Site URL will be updated
                        </Text>
                    </HStack>}
              </ModalBody>

              <ModalFooter>
                <Button key={"wh-save-button"} variant="brand" isLoading={isAuthRedirecting}
                        loadingText={"Redirecting..."}
                        onClick={handleWoocommerceAuthorization}>Connect</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Card mb='20px'>
            <Flex align='center' w='100%' justify='space-between' mb='30px'>
              <Text fontWeight='bold' fontSize='2xl' mb='4px'>
                Woocommerce Plugin
              </Text>
              {/***********************************   CONNECT WOOCOMMERCE BUTTON   **********************************/}
              <ButtonGroup alignItems={"right"} variant="outline" spacing="6">
                <Button variant={"brand"} onClick={onWooAuthOpen} disabled={isAuthorizeSite()}>Connect</Button>
              </ButtonGroup>
            </Flex>
            {/***********************************   WOOCOMMERCE AUTHORIZED SITE TABLE STATUS   **********************************/}
            {authorizedSiteUrl && authorizedSiteStatus &&
                <TableContainer borderColor={"black"}>
                    <Table variant='simple'>
                        <Tbody>
                            <Tr>
                                <Td textAlign={"left"}>{authorizedSiteUrl}</Td>

                                <Td textAlign={"center"}>{authorizedSiteStatus ? <>Connected<Icon ml={2} mb={1}
                                                                                                   as={CheckCircleIcon}
                                                                                                   color='green'/></> : "Unauthorized"}</Td>
                                <Td textAlign={"right"}><Button
                                    onClick={() => deleteSiteAuthorization()}>Delete</Button></Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </TableContainer>
            }
            {!authorizedSiteUrl && !authorizedSiteStatus && isAuthorizeSiteStatusLoading &&
                <Flex align='center' w='100%' justify='center'>
                    <Spinner thickness='6px' speed='0.65s' emptyColor='gray.200' color='blue.500' boxSize={24} mb={20}/>
                </Flex>
            }
            {!authorizedSiteUrl && !authorizedSiteStatus && !isAuthorizeSiteStatusLoading &&
                <Flex align='center' w='100%' justify='center'>
                    <Text fontSize='l' mb='4px' color={"grey"}>
                        No woocommerce sites have been authorized yet. Click on 'Connect' to add one
                    </Text>
                </Flex>
            }
          </Card>
        </form>
        <Flex align='center' w='100%' justify='center' mb='30px'>
          <Text fontSize='l' mb='4px' color={"grey"}>
            Learn how to create your Woocommerce API Consumer key and secret to manage your orders by checking out
            our <Link
            textDecoration={"underline"} href="https://docs.sprintcheckout.com" isExternal>Docs</Link>
          </Text>
        </Flex>
      </Box>

      <Box pt={{base: '180px', md: '80px', xl: '10px'}}>
        <Card mb='20px'>
          <Flex align='center' w='100%' justify='space-between' mb='30px'>
            <Text fontWeight='bold' fontSize='2xl' mb='4px'>
              Orders
            </Text>
          </Flex>
          {/***********************************   MERCHANT ORDERS   **********************************/}
          {((!currentData || currentData.length == 0) && !isOrdersLoading) &&
              <Flex align='center' w='100%' justify='center' mb='30px'>
                  <Text fontSize='l' mb='4px' color={"grey"}>
                      No orders processed yet in your merchant
                  </Text>
              </Flex>
          }
          {(currentData && currentData.length > 0) &&
              <TableContainer borderColor={"black"}>
                  <Table variant='simple'>
                      <TableCaption>
                          <Button mr={2} onClick={prevPage} hidden={currentPage === 0} disabled={currentPage === 0}>
                              <ChevronLeftIcon />
                          </Button>
                          Page {currentPage + 1} of {totalPages}
                          <Button ml={2} onClick={nextPage} hidden={currentPage === totalPages - 1} disabled={currentPage === totalPages - 1}>
                              <ChevronRightIcon />
                          </Button>
                      </TableCaption>
                      <Thead>
                          <Tr>
                              <Th textAlign={"left"}> Type</Th>
                              <Th textAlign={"left"}> Template</Th>
                              <Th textAlign={"left"}> Concept</Th>
                              <Th textAlign={"left"}> Reference</Th>
                              <Th textAlign={"left"}> Date</Th>
                              <Th textAlign={"left"}> Block Explorer</Th>
                          </Tr>
                      </Thead>
                      <Tbody>
                        {currentData.filter(order => order.receipts && order.receipts.length > 0)
                          .map((order, index) => (
                          <Tr key={"order_" + index}>
                            <Td textAlign={"left"}>{order.sessionType? order.sessionType.replace('_', ' ') : ''}</Td>
                            <Td
                              textAlign={"left"}>{order.template? order.template.charAt(0).toUpperCase() + order.template.slice(1).toLowerCase() : 'n/a'}</Td>
                            <Td textAlign={"left"}>{(order.concept && order.concept.length > 0) ? order.concept : ""}</Td>
                            <Td textAlign={"left"}>{order.orderId}</Td>
                            <Td textAlign={"left"}>{new Date(
                              parseInt(order.createdDate[0]),
                              parseInt(order.createdDate[1]) - 1,
                              parseInt(order.createdDate[2]),
                              parseInt(order.createdDate[3]),
                              parseInt(order.createdDate[4]),
                              parseInt(order.createdDate[5]),
                              +order.createdDate[6] / 1000000
                            ).toLocaleDateString('en-GB', {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                            })}</Td>
                            <Td textAlign={"left"}>
                              <a href={order.receipts.at(0)} target="_blank" rel="noopener noreferrer"
                                 style={{color: '#422AFB'}}>
                                {order.receipts.at(0).match(/\/\/([^\/]+)/)?.[1] || ""}
                              </a>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                  </Table>
              </TableContainer>
          }

          {(isOrdersLoading && (!currentData || currentData.length === 0)) &&
              <Flex align='center' w='100%' justify='center'>
                  <Spinner thickness='6px' speed='0.65s' emptyColor='gray.200' color='blue.500' boxSize={24} mb={20}/>
              </Flex>
          }
        </Card>
      </Box>
    </>
  )
    ;

}
