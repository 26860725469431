/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Icon,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  SimpleGrid,
  Spinner,
  Stack,
  Switch,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Textarea,
  Tr,
  useDisclosure
} from '@chakra-ui/react';
// Assets
// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import axios, {AxiosError} from "axios";
import {
  ApiKeySecret,
  Autosettlement,
  Chain,
  PaymentSettings,
  Token,
  TokenAutoSettle
} from "../../../types/payment.types";
import {MdCheck, MdCurrencyExchange, MdWallet} from "react-icons/md";
import {PiTestTube} from "react-icons/pi";

const EthIcon: string = 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none"><path fill="%2325292E" fill-rule="evenodd" d="M14 28a14 14 0 1 0 0-28 14 14 0 0 0 0 28Z" clip-rule="evenodd"/><path fill="url(%23a)" fill-opacity=".3" fill-rule="evenodd" d="M14 28a14 14 0 1 0 0-28 14 14 0 0 0 0 28Z" clip-rule="evenodd"/><path fill="url(%23b)" d="M8.19 14.77 14 18.21l5.8-3.44-5.8 8.19-5.81-8.19Z"/><path fill="%23fff" d="m14 16.93-5.81-3.44L14 4.34l5.81 9.15L14 16.93Z"/><defs><linearGradient id="a" x1="0" x2="14" y1="0" y2="28" gradientUnits="userSpaceOnUse"><stop stop-color="%23fff"/><stop offset="1" stop-color="%23fff" stop-opacity="0"/></linearGradient><linearGradient id="b" x1="14" x2="14" y1="14.77" y2="22.96" gradientUnits="userSpaceOnUse"><stop stop-color="%23fff"/><stop offset="1" stop-color="%23fff" stop-opacity=".9"/></linearGradient></defs></svg>%0A';
const ZkSyncIcon: string = 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 28 28"><g clip-path="url(%23a)"><path fill="%23F9F7EC" d="M14 28c7.732 0 14-6.268 14-14S21.732 0 14 0 0 6.268 0 14s6.268 14 14 14Z"/><path fill="%23000" fill-rule="evenodd" d="m22.12 13.93-4.669-4.648v3.402l-4.634 3.409h4.634v2.485l4.669-4.648ZM5.67 13.93l4.669 4.648v-3.381l4.634-3.437h-4.634V9.275L5.67 13.93Z" clip-rule="evenodd"/></g><defs><clipPath id="a"><path fill="%23fff" d="M0 0h28v28H0z"/></clipPath></defs></svg>';
const PolygonIcon: string = 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28" height="28"><defs><linearGradient id="A" x1="-18.275%" x2="84.959%" y1="8.219%" y2="71.393%"><stop offset="0%" stop-color="%23a229c5"/><stop offset="100%" stop-color="%237b3fe4"/></linearGradient><circle id="B" cx="14" cy="14" r="14"/></defs><g fill-rule="evenodd"><mask id="C" fill="%23fff"><use xlink:href="%23B"/></mask><g fill-rule="nonzero"><path fill="url(%23A)" d="M-1.326-1.326h30.651v30.651H-1.326z" mask="url(%23C)"/><path fill="%23fff" d="M18.049 17.021l3.96-2.287a.681.681 0 0 0 .34-.589V9.572a.683.683 0 0 0-.34-.59l-3.96-2.286a.682.682 0 0 0-.68 0l-3.96 2.287a.682.682 0 0 0-.34.589v8.173L10.29 19.35l-2.777-1.604v-3.207l2.777-1.604 1.832 1.058V11.84l-1.492-.861a.681.681 0 0 0-.68 0l-3.96 2.287a.681.681 0 0 0-.34.589v4.573c0 .242.13.468.34.59l3.96 2.286a.68.68 0 0 0 .68 0l3.96-2.286a.682.682 0 0 0 .34-.589v-8.174l.05-.028 2.728-1.575 2.777 1.603v3.208l-2.777 1.603-1.83-1.056v2.151l1.49.86a.68.68 0 0 0 .68 0z"/></g></g></svg>';
const PolygonZkEvmIcon: string = PolygonIcon;
const OptimismIcon: string = 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none"><rect width="28" height="28" fill="%23FF3131" rx="14"/><rect width="28" height="28" fill="url(%23a)" fill-opacity=".3" rx="14"/><path fill="%23fff" d="M9.22 18.35c2.7 0 4.86-2.2 4.86-5.38 0-2.19-1.47-3.8-3.98-3.8-2.72 0-4.85 2.2-4.85 5.38 0 2.2 1.5 3.8 3.97 3.8Zm.83-7.35c1.06 0 1.74.81 1.74 2.1 0 1.9-1.11 3.42-2.51 3.42-1.06 0-1.74-.82-1.74-2.1 0-1.89 1.11-3.42 2.5-3.42Zm6.38-1.68-1.88 8.88h2.26l.55-2.6h1.47c2.43 0 4.01-1.38 4.01-3.6 0-1.61-1.17-2.68-3.1-2.68h-3.3Zm1.9 1.74h.94c.83 0 1.3.38 1.3 1.14 0 1-.68 1.7-1.74 1.7h-1.11l.6-2.84Z"/><defs><linearGradient id="a" x1="0" x2="14" y1="0" y2="28" gradientUnits="userSpaceOnUse"><stop stop-color="%23fff"/><stop offset="1" stop-color="%23fff" stop-opacity="0"/></linearGradient></defs></svg>%0A';
const AvalancheIcon: string = 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none"><path fill="%23fff" d="M23 5H5v18h18V5Z"/><path fill="%23E84142" fill-rule="evenodd" d="M14 28c-7.513.008-14-6.487-14-14C0 6.196 6.043-.008 14 0c7.95.008 14 6.196 14 14 0 7.505-6.495 13.992-14 14Zm-3.971-7.436H7.315c-.57 0-.851 0-1.023-.11a.69.69 0 0 1-.313-.54c-.01-.202.13-.45.412-.944l6.7-11.809c.285-.501.43-.752.612-.845.195-.1.429-.1.625 0 .182.093.326.344.611.845l1.377 2.404.007.013c.308.538.464.81.533 1.097a2.04 2.04 0 0 1 0 .954c-.07.289-.224.564-.536 1.11l-3.52 6.22-.009.017c-.31.542-.467.817-.684 1.024a2.048 2.048 0 0 1-.835.485c-.285.079-.604.079-1.243.079Zm6.852 0h3.888c.574 0 .862 0 1.034-.113a.687.687 0 0 0 .313-.543c.01-.196-.128-.434-.398-.9a8.198 8.198 0 0 1-.028-.048l-1.948-3.332-.022-.037c-.274-.463-.412-.697-.59-.787a.684.684 0 0 0-.621 0c-.179.093-.323.337-.608.828l-1.94 3.331-.007.012c-.284.49-.426.735-.416.936.014.22.127.423.313.543.168.11.456.11 1.03.11Z" clip-rule="evenodd"/></svg>%0A';
const ArbitrumIcon: string = 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none"><rect width="26.6" height="26.6" x=".7" y=".7" fill="%232D374B" stroke="%2396BEDC" stroke-width="1.4" rx="13.3"/><mask id="a" width="28" height="28" x="0" y="0" maskUnits="userSpaceOnUse" style="mask-type:alpha"><rect width="28" height="28" fill="%23C4C4C4" rx="14"/></mask><g mask="url(%23a)"><path fill="%2328A0F0" d="m14.0861 18.6041 6.5014 10.2239 4.0057-2.3213-7.86-12.3943-2.6471 4.4917Zm13.0744 3.4692-.003-1.8599-7.3064-11.407-2.3087 3.9173 7.091 11.4303 2.172-1.2586a.9628.9628 0 0 0 .3555-.7009l-.0004-.1212Z"/><rect width="25.9" height="25.9" x="1.05" y="1.05" fill="url(%23b)" fill-opacity=".3" stroke="%2396BEDC" stroke-width="2.1" rx="12.95"/><path fill="%23fff" d="m.3634 28.2207-3.07-1.7674-.234-.8333L7.7461 9.0194c.7298-1.1913 2.3197-1.575 3.7957-1.5541l1.7323.0457L.3634 28.2207ZM19.1655 7.511l-4.5653.0166L2.24 27.9533l3.6103 2.0788.9818-1.6652L19.1655 7.511Z"/></g><defs><linearGradient id="b" x1="0" x2="14" y1="0" y2="28" gradientUnits="userSpaceOnUse"><stop stop-color="%23fff"/><stop offset="1" stop-color="%23fff" stop-opacity="0"/></linearGradient></defs></svg>%0A';
// const ScrollIcon: string = '';
// const LineaIcon: string = '';
const BaseIcon: string = 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28"><g fill="none" fill-rule="evenodd"><path fill="%230052FF" fill-rule="nonzero" d="M14 28a14 14 0 1 0 0-28 14 14 0 0 0 0 28Z"/><path fill="%23FFF" d="M13.967 23.86c5.445 0 9.86-4.415 9.86-9.86 0-5.445-4.415-9.86-9.86-9.86-5.166 0-9.403 3.974-9.825 9.03h14.63v1.642H4.142c.413 5.065 4.654 9.047 9.826 9.047Z"/></g></svg>';
const BinanceIcon: string = 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none"><g clip-path="url(%23a)"><path fill="%23F0B90B" fill-rule="evenodd" d="M14 0c7.733 0 14 6.267 14 14s-6.267 14-14 14S0 21.733 0 14 6.267 0 14 0Z" clip-rule="evenodd"/><path fill="%23fff" d="m7.694 14 .01 3.702 3.146 1.85v2.168l-4.986-2.924v-5.878L7.694 14Zm0-3.702v2.157l-1.832-1.083V9.214l1.832-1.083 1.841 1.083-1.84 1.084Zm4.47-1.084 1.832-1.083 1.84 1.083-1.84 1.084-1.832-1.084Z"/><path fill="%23fff" d="M9.018 16.935v-2.168l1.832 1.084v2.157l-1.832-1.073Zm3.146 3.394 1.832 1.084 1.84-1.084v2.157l-1.84 1.084-1.832-1.084V20.33Zm6.3-11.115 1.832-1.083 1.84 1.083v2.158l-1.84 1.083v-2.157l-1.832-1.084Zm1.832 8.488.01-3.702 1.831-1.084v5.879l-4.986 2.924v-2.167l3.145-1.85Z"/><path fill="%23fff" d="m18.982 16.935-1.832 1.073v-2.157l1.832-1.084v2.168Z"/><path fill="%23fff" d="m18.982 11.065.01 2.168-3.155 1.85v3.712l-1.831 1.073-1.832-1.073v-3.711l-3.155-1.851v-2.168l1.84-1.083 3.135 1.86 3.155-1.86 1.84 1.083h-.007Zm-9.964-3.7 4.977-2.935 4.987 2.935-1.832 1.083-3.154-1.86-3.146 1.86-1.832-1.083Z"/></g><defs><clipPath id="a"><path fill="%23fff" d="M0 0h28v28H0z"/></clipPath></defs></svg>';

const chainsDisplayNames: Record<string, { displayName: string; icon: string }> = {
  "ethereum-mainnet": { displayName: "Ethereum", icon: EthIcon },
  "zksync-goerli": { displayName: "zkSync Era (goerli)", icon: ZkSyncIcon },
  "zksync-mainnet": { displayName: "zkSync Era", icon: ZkSyncIcon },
  "polygon-mumbai": { displayName: "Polygon zkEVM (mumbai)", icon: PolygonIcon },
  "polygon-mainnet": { displayName: "Polygon", icon: PolygonIcon },
  "polygonzkevm-mainnet": { displayName: "Polygon zkEVM", icon: PolygonZkEvmIcon },
  "optimism-mainnet": { displayName: "Optimism", icon: OptimismIcon },
  "optimism-goerli": { displayName: "Optimism (goerli)", icon: OptimismIcon },
  "avalanche-fuji": { displayName: "Avalanche (fuji)", icon: AvalancheIcon },
  "avalanche-mainnet": { displayName: "Avalanche", icon: AvalancheIcon },
  "arbitrum-mainnet": { displayName: "Arbitrum", icon: ArbitrumIcon },
  "scroll-mainnet": { displayName: "Scroll", icon: EthIcon },
  "linea-mainnet": { displayName: "Linea", icon: EthIcon },
  "base-mainnet": { displayName: "Base", icon: BaseIcon },
  "binance-mainnet": { displayName: "Binance BNB", icon: BinanceIcon }
};

export default function Setup() {

  const [paymentSettingsBackend, setPaymentSettingsBackend] = useState<PaymentSettings>();
  const [chainsReady, setChainsReady] = useState<boolean>(false);
  const [isFormSaving, setIsFormSaving] = useState<boolean>(false);
  const {isOpen, onOpen, onClose} = useDisclosure()

  const {isOpen: isAddApiKeyOpen, onOpen: onAddApiKeyOpen, onClose: onAddApiKeyClose} = useDisclosure()
  const [openedModalName, setOpenedModalName] = useState<string | null>(null);
  const textColorSecondary = 'secondaryGray.600';

  const openModal = (exchangeName: string) => {
    resetAutossetleApi();
    setOpenedModalName(exchangeName);
    onAddApiKeyOpen();
  };

  type ExchangeData = {
    tokenAutoSettleList: {
      token: string;
      tokenAddress: string;
      autoSettleCurrency: string;
    }[];
    exchangeApiKey: string;
    exchangeApiSecret: string;
    exchangeWebhook: string;
  }

  const {
    handleSubmit,
    register,
    control,
    formState: {errors},
    getValues
  } = useForm()

  const {
    handleSubmit: handleSubmitAutosettleApi,
    register: registerAutosettleApi,
    control: controlAutosettleApi,
    formState: {errors: errorsAutosettleApi},
    getValues: getValuesAutosettleApi,
    reset: resetAutossetleApi
  } = useForm();

  function onSubmitAutosettleApi(values: any) {
    saveApiKeyAndSecret(values);
  }

  function onSubmit(values: any) {
    //console.log("form values", values);
    setIsFormSaving(true);
    return new Promise((resolve) => {
      let paymentSettingToUpdate = {} as PaymentSettings
      let chainsToUpdate = new Array<Chain>()
      //Process chains
      Object.keys(values).forEach(function (key) {
        if (key.startsWith("chain_")) {
          // Process Chains
          let chain = {} as Chain
          let strTokens = key.split("_");
          chain.name = strTokens.at(1);
          chain.network = strTokens.at(2);
          chain.id = Number(strTokens.at(3));

          if (key.startsWith("chain_")) {
            chain.active = values[key];
          }
          if (key.startsWith("publicAddress_")) {
            chain.publicAddress = values[key];
          }

          //Process Tokens
          chain.tokens = new Array<Token>();
          Object.keys(values).forEach(function (key) {
            if (key.startsWith("token_") && key.indexOf(chain.name + "_" + chain.network) !== -1) {
              let token = {} as Token
              let strTokens = key.split("_");
              token.symbol = strTokens.at(3).toUpperCase();
              token.active = values[key];
              chain.tokens.push(token);
            }
          });
          chainsToUpdate.push(chain);
        }
      });

      chainsToUpdate.forEach((chain) => {
        chain.publicAddress = values["publicAddress_" + chain.name + "_" + chain.network];
      })

      // Process auto-settle (exchanges) based on the form's values
      let autosettlements: Autosettlement[] = [];

      Object.keys(values).forEach(key => {
        const splitKey = key.split('_');
        if (key.includes('autosettle_tokenAddress_')) {
          const exchangeName = splitKey[0];  // Using the prefix as the exchange name
          const exchangeTokenName = splitKey.slice(-1)[0]; // Getting the token name from the end

          // Look for an existing Autosettlement for the given exchange
          let autosettlement = autosettlements.find(a => a.exchangeName === exchangeName);

          // If none is found, create one and add it to the list
          if (!autosettlement) {
            autosettlement = {
              exchangeName: exchangeName, // add exchange name to the autosettlement
              tokenAutoSettleList: [],
              exchangeApiKey: values[`${exchangeName}_exchangeApiKey`],
              exchangeApiSecret: values[`${exchangeName}_exchangeApiSecret`],
            };
            autosettlements.push(autosettlement);
          }

          const tokenAutoSettle: TokenAutoSettle = {
            token: exchangeTokenName,
            tokenAddress: values[`${exchangeName}_autosettle_tokenAddress_${exchangeTokenName}`],
            autoSettleCurrency: values[`${exchangeName}_autosettle_currency_${exchangeTokenName}`]
          };
          autosettlement.tokenAutoSettleList.push(tokenAutoSettle);

          // Process autosettle inside Tokens
          const exchangeTokenSymbol = splitKey.slice(-1)[0].split(' ')[1];
          const exchangeChainName = splitKey.slice(-1)[0].split(' ')[0];

          // Find the matching Chain object
          const chain = chainsToUpdate.find(c => c.name.toLowerCase() === exchangeChainName.toLowerCase());

          if (chain) {
            // Find the matching Token object inside the Chain
            const token = chain.tokens.find(t => t.symbol === exchangeTokenSymbol);

            if (token && !token.exchangeName && !token.isAutosettlementEnabled) {
              // Update Token properties
              token.cexPublicAddress = values[key];
              token.autoSettleCurrency = values[`${exchangeName}_autosettle_currency_${exchangeChainName} ${exchangeTokenSymbol}`];
              token.isAutosettlementEnabled = token.autoSettleCurrency !== 'NO';

              // If needed, update exchange-related properties
              token.exchangeName = exchangeName;
            }
          }
        }
      });

      paymentSettingToUpdate.businessUrl = values.businessUrl;
      paymentSettingToUpdate.businessDescription = values.businessDescription;
      paymentSettingToUpdate.merchantName = values.merchantName;
      paymentSettingToUpdate.chains = chainsToUpdate;
      paymentSettingToUpdate.merchantLogoUrl = values.merchantLogoUrl;
      paymentSettingToUpdate.autosettlements = autosettlements;
      updateMerchantPaymentSettings(paymentSettingToUpdate);
    })
  }

  function saveApiKeyAndSecret(values: any) {

    const results: ApiKeySecret[] = [];
    const map: Record<string, ApiKeySecret> = {};

    Object.keys(values).forEach(key => {

      const splitKey = key.split('_');
      const exchangeName = splitKey[0];
      const fieldType = splitKey[2];
      if (!map[exchangeName]) {
        map[exchangeName] = {
          exchangeName,
          apiKey: "",
          apiSecret: ""
        };
      }
      if (values[key] !== undefined) {
        if (fieldType === 'key') {
          map[exchangeName].apiKey = values[key];
        } else if (fieldType === 'secret') {
          map[exchangeName].apiSecret = values[key];
        }
      }
    });

    Object.values(map).forEach(exchange => {
      if (exchange.apiKey || exchange.apiSecret) { // Only add if either field is set.
        results.push(exchange);
      }
    });

    try {
      let paymentSettingsResponse = axios.post(process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL + '/payment_settings/apiKeys', JSON.parse(JSON.stringify(results.at(0))), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('jwtToken')
        }
      });
      paymentSettingsResponse.then(result => {
        if (result.status === 200) {
          setPaymentSettingsBackend(result.data);
          onAddApiKeyClose();
          onOpen();
          setTimeout(() => {
            onClose();
          }, 3 * 1000);
        }
      });
    } catch (error) {
      setIsFormSaving(false);
      const err = error as AxiosError
      if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
        window.location.replace("/#/auth/set-up");
      }
    }
  }

  function updateMerchantPaymentSettings(paymentSettingToUpdate: PaymentSettings) {

    try {
      let paymentSettingsResponse = axios.put(process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL + '/payment_settings', JSON.parse(JSON.stringify(paymentSettingToUpdate)), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('jwtToken')
        }
      });
      paymentSettingsResponse.then(result => {
        setIsFormSaving(false);
        if (result.status === 200) {
          onOpen();
          setTimeout(() => {
            onClose();
          }, 3 * 1000);
        }
      });
    } catch (error) {
      setIsFormSaving(false);
      const err = error as AxiosError
      if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
        window.location.replace("/#/auth/set-up");
      }
    }
  }

  async function getMerchantPaymentSettings() {

    try {
      return await axios.get(process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL + '/payment_settings', {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('jwtToken')
        }
      });
    } catch (error) {
      const err = error as AxiosError
      if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
        window.location.replace("#/auth/set-up");
      }
      if (err.response?.status === 404) {
        console.log("Merchant Not Found")
        await createNewPaymentSettings()
      }
    }
  }

  async function createNewPaymentSettings() {
    try {
      var defaultPaymentSettings = await axios.post(process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL + '/payment_settings', {}, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('jwtToken')
        }
      });
      setPaymentSettingsBackend(defaultPaymentSettings.data);
    } catch (error) {
      const err = error as AxiosError
      if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
        window.location.replace("/#/auth/set-up");
      }
    }
  }

  function generateValidationRule(value: string, chainName: string, chainNetwork: string, chainId: string) {
    const fieldName = `chain_${chainName}_${chainNetwork}_${chainId}`;
    return (getValues()[fieldName] === false || (value.length > 0 && getValues()[fieldName] === true)) ? true : 'Public address is required when network is active';
  }

  function capitalizeFirstChar(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleRevoke = async (autosettlement: Autosettlement) => {
    try {
      const url = `${process.env.REACT_APP_SPRINTCHECKOUT_BASE_URL}/payment_settings/apiKeys/${autosettlement.exchangeName}`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('jwtToken')
        }
      });

      if (response.status === 200) {
        setPaymentSettingsBackend(response.data);
        console.log('API key and secret revoked successfully.');
      }
    } catch (error) {
      console.error('Failed to revoke API key and secret:', error);
      const err = error as AxiosError
      if (err.response?.status === 401 || err.code === 'ERR_NETWORK') {
        window.location.replace("/#/auth/set-up");
      }

    }
  }

  useEffect(() => {
    getMerchantPaymentSettings().then(paymentSettings => {
      if (paymentSettings) {
        let castPaymentSettings: PaymentSettings = JSON.parse(JSON.stringify(paymentSettings.data));
        setPaymentSettingsBackend(castPaymentSettings);
        setChainsReady(true);
      }
    });
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay/>
          <ModalContent>
            <ModalHeader>Settings saved</ModalHeader>
            <ModalCloseButton/>
            <ModalBody>
              <Alert status='success' variant='subtle'>
                <AlertIcon/>
                Your settings have been saved successfully!
              </Alert>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/***********************************   BUTTONS (SAVE)   **********************************/}
        <Box pt={{base: '130px', md: '80px', xl: '80px'}}>
          <Flex align='end' w='100%' justify='flex-end'>
            <ButtonGroup alignItems={"right"} variant="outline" spacing="6">
              <Button width={130} variant="brand" type={"submit"} isLoading={isFormSaving}
                      loadingText={"saving"}>Save</Button>
            </ButtonGroup>
          </Flex>
        </Box>
        {/***********************************   YOUR BUSINESS (NAME, URL, DESCRIPTION)   **********************************/}
        {!paymentSettingsBackend &&
            <Flex align='center' w='100%' justify='center' mt={250}>
                <Spinner
                    thickness='6px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.500'
                    boxSize={24}
                />
            </Flex>
        }
        {paymentSettingsBackend && <Box pt={{base: '130px', md: '80px', xl: '30px'}}>
            <Card>
                <SimpleGrid columns={{sm: 1, md: 2}} spacing={{base: '20px', xl: '20px'}}>
                    <Flex align='center' w='100%' justify='space-between' mb='30px'>
                        <Text fontWeight='bold' fontSize='2xl' mb='4px'>
                            Your business
                        </Text>
                    </Flex>
                </SimpleGrid>

              {/*<Flex align='center' w='100%' justify='space-between' mb='30px'>*/}
                <Text mb='10px' mr='10px' ml={0.5}>
                    Name
                </Text>
                <Input {...register("merchantName")}
                       placeholder={"Alice Flowers"}
                       defaultValue={paymentSettingsBackend.merchantName}
                       mb='17px'
                ></Input>
              {/*</Flex>*/}
              {/*<Flex align='center' w='100%' justify='space-between' mb='30px'>*/}
                <Text mb='10px' ml={0.5}>Site URL</Text>
                <Input {...register("businessUrl",
                  {
                    required: 'URL is required',
                    pattern: {
                      value: /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/i,
                      message: 'Invalid URL format'
                    }
                  })}
                       placeholder={"https://www.alice-flowers.com"}
                       defaultValue={paymentSettingsBackend.businessUrl}
                       mb='17px'
                ></Input>
              {errors.businessUrl &&
                  <Text ml={0.5} mt='-10px' mb={2} color={"red.400"}>{String(errors.businessUrl?.message)}</Text>}
              {/*<Tooltip placement='auto-start' label="The image referenced in the URL should fit 300x40 aspect ratio" aria-label='A tooltip'>*/}
                <Text mb='10px' ml={0.5}>Logo URL</Text>
                <Text mb='10px' ml={0.5} fontSize={"xs"}>Recommended dimensions: 300x40 pixels</Text>
              {/*</Tooltip>*/}
                <Input {...register("merchantLogoUrl",
                  {
                    pattern: {
                      value: /^(https?):\/\/[^\s$.?#].[^\s]*$/i,
                      message: 'Invalid URL format'
                    }
                  })}
                       placeholder={"https://via.placeholder.com/300x40"}
                       defaultValue={paymentSettingsBackend.merchantLogoUrl}
                       mb='17px'
                ></Input>
              {errors.merchantLogoUrl &&
                  <Text ml={0.5} mt='-10px' mb={2} color={"red.400"}>{String(errors.merchantLogoUrl?.message)}</Text>}
              {/*</Flex>*/}
              {/*<Flex align='center' w='100%' justify='space-between' mb='30px'>*/}
                <Text mb='10px' mr='10px' ml={0.5}>
                    Description
                </Text>
                <Textarea {...register("businessDescription")}
                          placeholder={"..."}
                          defaultValue={paymentSettingsBackend.businessDescription}
                          mb='17px'
                ></Textarea>
              {/*</Flex>*/}
            </Card>
        </Box>}

        {/***********************************   PAYMENT SETTINGS   **********************************/}
        {paymentSettingsBackend && <Box pt={{base: '180px', md: '80px', xl: '30px'}}>
            <Card mb='20px'>
                <SimpleGrid mb='20px' columns={{sm: 1, md: 2}} spacing={{base: '20px', xl: '20px'}}>
                    <Flex align='center' w='100%' justify='space-between' mb='10px'>
                        <Text fontWeight='bold' fontSize='2xl' mb='4px'>
                            Payout settings
                        </Text>
                    </Flex>
                </SimpleGrid>


                <Tabs variant='soft-rounded' colorScheme='blue' mb={5}>
                    <Flex as={TabList}>
                        <Tab mr={10}><Icon mr={2} as={MdWallet} width='20px' height='20px' color='inherit' />Wallets</Tab>
                      {paymentSettingsBackend.autosettlements.map((autosettlement, index) => (
                        <Tab mr={10} key={autosettlement.exchangeName}>
                          <Icon mr={2} as={MdCurrencyExchange} width='20px' height='20px' color='inherit' />
                          {autosettlement.exchangeName.charAt(0).toUpperCase() + autosettlement.exchangeName.slice(1)}
                        </Tab>
                      ))}
                    </Flex>

                    <TabPanels>
                      {/***********************************  WALLETS TAB  **********************************/}
                        <TabPanel mt={9}>
                          {/***********************************  WALLETS TAB CHAIN & PUBLIC ADDRESS   **********************************/}
                            <Tabs variant='soft-rounded' colorScheme='green'>
                                <TabList>
                                    <Tab><Icon mr={2} as={MdCheck} width='20px' height='20px' color='inherit' />Mainnet</Tab>
                                    <Tab><Icon mr={2} as={PiTestTube} width='20px' height='20px' color='inherit' />Testnet</Tab>
                                </TabList>
                                <TabPanels>
                                  {/***********************************   MAINNET   **********************************/}
                                    <TabPanel>
                                      {paymentSettingsBackend && Object.values(paymentSettingsBackend.chains.reduce((acc: {
                                        [key: string]: any
                                      }, chain) => {
                                        if (!acc[chain.name] && chain.network === "mainnet") {
                                          acc[chain.name] = chain;
                                        }
                                        return acc;
                                      }, {})).map((chain) => (
                                        <Box p={5} mb={5}
                                             mx="auto"
                                             bg="white"
                                             rounded="lg"
                                             overflow="hidden"
                                             boxShadow="1px 1px 5px 0.5px rgba(0, 0, 0, 0.1)"
                                        >
                                          <Box pb={1} mb={'10px'}>
                                            <Flex align={"center"}>
                                              <Image
                                                src={chainsDisplayNames[`${chain.name}-${chain.network}`]?.icon}
                                                boxSize="24px"
                                                mr={2}
                                                alt={`${chain.name} icon`}
                                              />
                                              <Text fontWeight="bold" fontSize="xl" mb="0">
                                                {chainsDisplayNames[`${chain.name}-${chain.network}`]?.displayName}
                                              </Text>
                                              <Switch ml={5} mt={0.5} colorScheme="brand"
                                                      {...register("chain_" + chain.name + "_" + chain.network + "_" + chain.id)}
                                                      key={"chain_" + chain.name + "_" + chain.network + "_" + chain.id}
                                                      defaultChecked={chain.active}
                                              />
                                            </Flex>
                                          </Box>
                                          {/*<Flex align='center' w='100%' justify='space-between' mb='30px'>*/}
                                          <Text mb='4px' mr='15px' ml={0.5}>
                                            Your public address
                                          </Text>
                                          {chain &&
                                              <Input                        {...register("publicAddress_" + chain.name + "_" + chain.network, {
                                                validate: {
                                                  isPublicAddressMandatory: value => generateValidationRule(value, chain.name, chain.network, chain.id),
                                                }
                                              })}
                                                                            placeholder="0x0000000000000000000000000000000000000000"
                                                                            borderRadius="10px"
                                                                            mt={1} mb={6} size="lg"
                                                                            key={"publicAddress_" + chain.name + "_" + chain.network}
                                                                            defaultValue={chain.publicAddress}/>
                                          }
                                          {errors[`publicAddress_${chain.name}_${chain.network}`] &&
                                              <Text ml={0.5} mt='-10px' mb={2}
                                                    color={"red.400"}>{String(errors[`publicAddress_${chain.name}_${chain.network}`]?.message)}</Text>
                                          }
                                          {/*</Flex>*/}
                                          <Accordion allowToggle
                                                     mb={5} /* TODO check radius for dark theme outline={"1px solid #e0dddd"} borderRadius={"5px"}*/>
                                            <AccordionItem /* TODO check border for dark theme borderStyle={"none"}*/>
                                              <h2>
                                                <AccordionButton>
                                                  <Box as="span" flex='1' textAlign='left'>
                                                    <Text mb='4px' mr='15px' style={{whiteSpace: 'nowrap'}}>
                                                      Available tokens
                                                    </Text>
                                                  </Box>
                                                  <AccordionIcon/>
                                                </AccordionButton>
                                              </h2>
                                              <AccordionPanel pb={4}>
                                                <Table variant="simple">
                                                  <Tbody>
                                                    {chain.tokens && chain.tokens.map((token: Token) => {
                                                      return <Tr>
                                                        <Td paddingInlineStart={0} paddingInlineEnd={0}><Image maxWidth={'none'} w="32px" h="32px" src={token.iconUrl} /></Td>
                                                        <Td paddingInlineEnd={0}>{token.symbol.toUpperCase()}</Td>

                                                        <Td><Switch {...register("token_" + chain.name + "_" + chain.network + "_" + token.symbol.toLowerCase())}
                                                                    key={chain.name + chain.network + token.symbol}
                                                                    colorScheme="brand"
                                                                    defaultChecked={token.active}/></Td>
                                                      </Tr>;
                                                    })}
                                                  </Tbody>
                                                </Table>
                                              </AccordionPanel>
                                            </AccordionItem>
                                          </Accordion>
                                        </Box>
                                      ))}
                                    </TabPanel>
                                  {/***********************************   TESTNET   **********************************/}
                                    <TabPanel>
                                      {paymentSettingsBackend && Object.values(paymentSettingsBackend.chains.reduce((acc: {
                                        [key: string]: any
                                      }, chain) => {
                                        if (!acc[chain.name] && chain.network !== "mainnet") {
                                          acc[chain.name] = chain;
                                        }
                                        return acc;
                                      }, {})).map((chain) => (
                                        <Box p={5} mb={5}
                                             mx="auto"
                                             bg="white"
                                             rounded="lg"
                                             overflow="hidden"
                                             boxShadow="1px 1px 5px 0.5px rgba(0, 0, 0, 0.1)"
                                        >
                                          <Box pb={1} mb={'10px'}>
                                            <Flex align={"center"}>
                                              <Image
                                                src={chainsDisplayNames[`${chain.name}-${chain.network}`]?.icon}
                                                boxSize="24px"
                                                mr={2}
                                                alt={`${chain.name} icon`}
                                              />
                                              <Text fontWeight='bold' fontSize='xl' mb='0'>
                                                {chainsDisplayNames[`${chain.name}-${chain.network}`]?.displayName}
                                              </Text>
                                              <Switch ml={5} mt={0.5} colorScheme="brand"
                                                      {...register("chain_" + chain.name + "_" + chain.network + "_" + chain.id)}
                                                      key={"chain_" + chain.name + "_" + chain.network + "_" + chain.id}
                                                      defaultChecked={chain.active}
                                              />
                                            </Flex>
                                          </Box>
                                          {/*<Flex align='center' w='100%' justify='space-between' mb='30px'>*/}
                                          <Text mb='4px' mr='15px' ml={0.5}>
                                            Your public address
                                          </Text>
                                          {chain &&
                                              <Input                        {...register("publicAddress_" + chain.name + "_" + chain.network, {
                                                validate: {
                                                  isPublicAddressMandatory: value => generateValidationRule(value, chain.name, chain.network, chain.id),
                                                }
                                              })}
                                                                            placeholder="0x0000000000000000000000000000000000000000"
                                                                            borderRadius="10px"
                                                                            mt={1} mb={6} size="lg"
                                                                            key={"publicAddress_" + chain.name + "_" + chain.network}
                                                                            defaultValue={chain.publicAddress}/>
                                          }
                                          {errors[`publicAddress_${chain.name}_${chain.network}`] &&
                                              <Text ml={0.5} mt='-15px' mb={2}
                                                    color={"red.400"}>{String(errors[`publicAddress_${chain.name}_${chain.network}`]?.message)}</Text>
                                          }
                                          {/*</Flex>*/}
                                          <Accordion allowToggle
                                                     mb={5} /* TODO check radius for dark theme outline={"1px solid #e0dddd"} borderRadius={"5px"}*/>
                                            <AccordionItem /* TODO check border for dark theme borderStyle={"none"}*/>
                                              <h2>
                                                <AccordionButton>
                                                  <Box as="span" flex='1' textAlign='left'>
                                                    <Text mb='4px' mr='15px' style={{whiteSpace: 'nowrap'}}>
                                                      Available tokens
                                                    </Text>
                                                  </Box>
                                                  <AccordionIcon/>
                                                </AccordionButton>
                                              </h2>
                                              <AccordionPanel pb={4}>
                                                <Table variant="simple">
                                                  <Tbody>
                                                    {chain.tokens && chain.tokens.map((token: Token, index: number) => {
                                                      return <Tr>
                                                        <Td paddingInlineStart={0} paddingInlineEnd={0}><Image maxWidth={'none'} w="32px" h="32px" src={token.iconUrl} /></Td>
                                                        <Td paddingInlineEnd={0}>{token.symbol.toUpperCase()}</Td>
                                                        <Td><Switch {...register("token_" + chain.name + "_" + chain.network + "_" + token.symbol.toLowerCase())}
                                                                    key={chain.name + chain.network + token.symbol}
                                                                    colorScheme="brand"
                                                                    defaultChecked={token.active}/></Td>
                                                      </Tr>;
                                                    })}
                                                  </Tbody>
                                                </Table>
                                              </AccordionPanel>
                                            </AccordionItem>
                                          </Accordion>
                                        </Box>
                                      ))}
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </TabPanel>
                      {/***********************************   AUTO-SETTLEMENT   **********************************/}
                      {/*TODO ADAPT THIS FORM TO STORE THE INFO IN THE CHAIN TOKENS*/}
                      {paymentSettingsBackend && paymentSettingsBackend.autosettlements.map((autosettlement, index) => (
                        <TabPanel mt={10} key={autosettlement.exchangeName}>
                          {autosettlement.tokenAutoSettleList.map(tokenInfo => (
                            // <Flex align='center' w='100%' justify='space-between' mb='30px' mt={5}>
                            <Card>
                              <Text mb='4px' mr='10px' style={{whiteSpace: 'nowrap'}}>
                                {capitalizeFirstChar(tokenInfo.token.replace('-', ' '))}
                              </Text>
                              <Input {...register(`${autosettlement.exchangeName}_autosettle_tokenAddress_${tokenInfo.token}`)}
                                     placeholder="0x0000000000000000000000000000000000000000"
                                     borderRadius="10px"
                                     size="lg"
                                     key={`${autosettlement.exchangeName}_autosettle_tokenAddress_${tokenInfo.token}`}
                                     defaultValue={tokenInfo.tokenAddress}
                              />
                              <Flex align='center' w='100%' my={5}>
                                <Text mr={5}>Autosettle: </Text>
                                {/*<Flex align='center' w='100%' justify='space-between' mb='30px' mt={5}>*/}
                                <Controller
                                  name={`${autosettlement.exchangeName}_autosettle_currency_${tokenInfo.token}`}
                                  control={control}
                                  defaultValue={tokenInfo.autoSettleCurrency}
                                  render={({field}) => (
                                    <RadioGroup {...field} value={field.value}>
                                      <Stack direction='row'>
                                        <Radio value='NO'>No</Radio>
                                        {!tokenInfo.token.includes('USDC') ?
                                          <Radio value='USD'>USD</Radio> : ""
                                        }
                                        <Radio value='EUR'>EUR</Radio>
                                        <Radio value='GBP'>GBP</Radio>
                                        {!tokenInfo.token.includes('USDC') ?
                                          <Radio value='USDC'>USDC</Radio> : ""
                                        }
                                      </Stack>
                                    </RadioGroup>
                                  )}
                                />
                              </Flex>
                              {/*</Flex>*/}
                            </Card>
                          ))}
                          <Divider/>
                          <Flex align='center' w='100%' justify='left' mb='20px' mt={30}>
                            <Text fontSize='l' mb='4px' fontStyle={"italic"} style={{whiteSpace: 'nowrap'}} mr={20}>
                              API key
                            </Text>
                            {(autosettlement.exchangeApiKey && autosettlement.exchangeApiKey !== '-') ?
                              <Text fontSize='l' mb='4px' fontStyle={"italic"}>
                                {autosettlement.exchangeApiKey}
                              </Text> :
                              <Text fontSize='l' mb='4px' color={"gray"} fontStyle={"italic"}
                                    style={{whiteSpace: 'nowrap'}}>
                                Click on Add API Key
                              </Text>
                            }
                            <Flex align='end' w='100%' justify='flex-end' mb='30px'>
                              <ButtonGroup alignItems={"right"} variant="outline" spacing="6">
                                <Button variant="brand" onClick={() => openModal(autosettlement.exchangeName)}>Add API
                                  key</Button>
                                <Button
                                  color={"red.400"}
                                  bg={"transparent"}
                                  disabled={!autosettlement.exchangeApiKey || autosettlement.exchangeApiKey === '-'}
                                  onClick={() => handleRevoke(autosettlement)}
                                >
                                  Revoke
                                </Button>
                              </ButtonGroup>
                            </Flex>
                          </Flex>
                        </TabPanel>
                      ))}
                    </TabPanels>
                </Tabs>
            </Card>
        </Box>}
      </form>
      {/***********************************   AUTO-SETTLEMENT API KEY AND SECRET MODAL  **********************************/}

      <form key={"autosettle-api-form"} id={"autosettle-api-form"}
            onSubmit={handleSubmitAutosettleApi(onSubmitAutosettleApi)}>
        {paymentSettingsBackend && paymentSettingsBackend.autosettlements.map((autosettlement, index) => (
          <Modal
            isOpen={isAddApiKeyOpen && openedModalName === autosettlement.exchangeName}
            onClose={() => {
              onAddApiKeyClose();
              setOpenedModalName(null);
            }}
          >
            <ModalOverlay/>
            <ModalContent>
              <ModalHeader>Add API key</ModalHeader>
              <ModalCloseButton/>
              <ModalBody pb={6}>
                <Text mb={2}>API key</Text>
                <Input {...registerAutosettleApi(`${autosettlement.exchangeName}_api_key`)}
                       placeholder={`${autosettlement.exchangeName}-generated-api-key`}
                       key="${autosettlement.exchangeName}-generated-api-key"/>
                <Text mt={4} mb={2}>API secret</Text>
                <Input {...registerAutosettleApi(`${autosettlement.exchangeName}_api_secret`)}
                       placeholder={`${autosettlement.exchangeName}-generated-api-secret`}
                       key="${autosettlement.exchangeName}-generated-api-secret"/>
              </ModalBody>

              <ModalFooter>
                <Button key={"as-save-button"} variant="brand" type={"submit"}
                        form={"autosettle-api-form"}>Save</Button>
                <Button key={"as-cancel-button"} onClick={() => {
                  onAddApiKeyClose();
                  setOpenedModalName(null);
                  resetAutossetleApi();
                }}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        ))}
      </form>
    </>
  );
}
